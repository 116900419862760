import React, {useState, useEffect} from 'react';
import { Outlet } from "react-router-dom";
import Navigator from './pages/navigator.tsx'
import Menu from './pages/menu.tsx'
import './App.css'


export default function App() {
console.log("into App.js")
const [menuShow, setMenuShow]=useState(false)
useEffect(()=>{
    const menuNode=document.getElementById('menuNode')   
    if(menuNode!==null) menuNode.addEventListener('mouseover', ()=>{ setMenuShow(true); console.log("enter"); })
    if(menuNode!==null) menuNode.addEventListener('mouseleave', ()=>{ setMenuShow(false);console.log("leave"); })
}, [menuShow])
  return(
    <>
    <nav className="navBar showNav" >
      <Navigator />
    </nav>
    <div  id='menuNode'>
      <div  className='menuTitle showMenu'>
        <span className='titleText'>Menu</span>   
        <p className="eqStyle">====</p>
        <div className="menu showMenu" style={{display: menuShow? 'block':'none'}}>
          <Menu />
        </div>
      </div> 
    </div>
    
    <div className="main_content">
      <Outlet />
    </div>
  </>

  );

}
