import React, { useState } from "react";
import '../styles/activity.css'

const std_photoW=420  
const std_photoH=300
const std_photoAmount=15
const std_step=1   //move speed
const std_freq=20  //move speed

export default function InterestGallery(){
    let photoWidth=std_photoW
    let photoHeight=std_photoH
    let photoAmount=std_photoAmount

    const [moveLeft, setMoveLeft]=useState(0)  
    let move=moveLeft
    const [saveNumber, setSaveNumber]=useState(0)
    let curNumber=saveNumber

    let dir=0
    let timer: number
    let step=std_step  //move step (speed)
    let freq = std_freq //move speed
    let count=0

        function moveSlides(){
 
            curNumber=curNumber+dir
            console.log("into moveSlides()***")
            action()
    
            function action(){
                step=count*count
                count=count+1
                move=move-step*dir
                
                if(dir>0){
                    if(move>-photoWidth*curNumber){ 
                        timer=setTimeout(action,freq)
                        setMoveLeft(move)
                        }  
                    else{
                        move=(-(photoWidth)*curNumber)
                        setMoveLeft(move)
                        setSaveNumber(curNumber)
                        count=0
                        step=std_step
                        clearTimeout(timer)
                    }  
                }
                if(dir<0){
                    if(move<-photoWidth*curNumber ){ 
                        timer=setTimeout(action,freq)
                        setMoveLeft(move)
                    }
                    else{
                        move=(-(photoWidth)*curNumber)
                        setMoveLeft(move)
                        setSaveNumber(curNumber)
                        count=0
                        step=std_step
                        clearTimeout(timer)
                    }
                }
            }
            
        }

    return(
    <>
          <div className="container" >
       
       <div id="list" className="photoList" style={{width:photoWidth+'px', height:photoHeight+'px'}}> 
         <img alt="photo_music_9" src="/assets_react/music_9.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_8" src="/assets_react/music_8.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_3" src="/assets_react/music_3.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_4" src="/assets_react/music_4.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_5" src="/assets_react/music_5.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_6" src="/assets_react/music_6.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_1" src="/assets_react/music_1.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_2" src="/assets_react/music_2.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         
         <img alt="photo_sport_1" src="/assets_react/sport_1.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_sport_2" src="/assets_react/sport_2.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_sport_3" src="/assets_react/sport_3.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_sport_4" src="/assets_react/sport_4.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_sport_5" src="/assets_react/sport_5.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_sport_6" src="/assets_react/sport_6.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
         <img alt="photo_music_7" src="/assets_react/music_7.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />

         <div className="rtIcon" style={{display: curNumber===photoAmount-1? 'none' : 'block'}} ><img alt="right" onClick={()=>{ dir=1; moveSlides() } } src="/assets_react/rt_icon.png" /></div>
         <div className="lfIcon" style={{display: curNumber===0? 'none' : 'block'}}><img alt="left" onClick={()=>{dir=-1; moveSlides()}}  src="/assets_react/lf_icon.png" /></div>
       </div>
           
   </div>
   <div className="boxList">
        <div className="sbox" style={{ backgroundColor: saveNumber===0? '#ccc' : ''}} onClick={()=>{curNumber=(0); setSaveNumber(0); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===1? '#ccc' : ''}} onClick={()=>{curNumber=(1); setSaveNumber(1); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===2? '#ccc' : ''}} onClick={()=>{curNumber=(2); setSaveNumber(2); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===3? '#ccc' : ''}} onClick={()=>{curNumber=(3); setSaveNumber(3); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===4? '#ccc' : ''}} onClick={()=>{curNumber=(4); setSaveNumber(4); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===5? '#ccc' : ''}} onClick={()=>{curNumber=(5); setSaveNumber(5); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===6? '#ccc' : ''}} onClick={()=>{curNumber=(6); setSaveNumber(6); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===7? '#ccc' : ''}} onClick={()=>{curNumber=(7); setSaveNumber(7); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===8? '#ccc' : ''}} onClick={()=>{curNumber=(8); setSaveNumber(8); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===9? '#ccc' : ''}} onClick={()=>{curNumber=(9); setSaveNumber(9); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===10? '#ccc' : ''}} onClick={()=>{curNumber=(10); setSaveNumber(10); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===11? '#ccc' : ''}} onClick={()=>{curNumber=(11); setSaveNumber(11); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===12? '#ccc' : ''}} onClick={()=>{curNumber=(12); setSaveNumber(12); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===13? '#ccc' : ''}} onClick={()=>{curNumber=(13); setSaveNumber(13); setMoveLeft(-photoWidth*curNumber)}}></div>
        <div className="sbox" style={{ backgroundColor: saveNumber===14? '#ccc' : ''}} onClick={()=>{curNumber=(14); setSaveNumber(14); setMoveLeft(-photoWidth*curNumber)}}></div>
   </div>
    </>
    )
}