import React from "react";
import LinkIcon from "./linkicon.tsx";
/*
export default function Academic(){
  return(
    <h1>Hello</h1>
  )
}
*/
export default function Academic(){

    return (
    <>  
        <div  className="main1Field">
        <ul>
          <li className="title">Courses
            <ul>
              <li>
                <p>MATH: 135-Algebra(Honours), 138-Calculus2(Honours), 136-Linear Alebra 1(Honours), 237-Calcilus 3(Honours), 235-Linear Algra 2(Honours) </p>
              </li>
              <li>
                <p>STAT: 230-Probability, 231-Statistics</p>
              </li>
              <li>
                 <p>CS: 135-Designing Functional Programs, 136-Elem Algo Dsgn and Data Abstrac, 136L-Tools & Tech for S/W Dev, 245-Logic & Computation, 246-Obj-Oriented Soft Dev, 230-Intro Computers & Comp Sys</p>
              </li>
              <li>
                 <p>Others: Intro Macroeconomics, Intro to Bus Organization, Entrepreneurial Strategy, Career Fundamentals, Music Theory, How Music Matters</p>
              </li>
            </ul>        
          </li>
          <li className="title" style={{marginTop: "1.2rem"}}>Programming in C and Java
              <p>During high school, I took AP Computer Science A and Advanced CS courses which strongly enriched my knowledge of Java. After I took CS135, CS136, and CS136L in my first year at University of Waterloo, I gained a deeper understanding of algorithm design and data abstraction in C.</p>
              <ul>
                <li>In Java, I learned the basic concepts of Object-oriented Programming (OOP) first, such as inheritance, polymorphism, and encapsulation. Moreover, I learned about JavaFX in depth and strongly solidified my knowledge of graphical user interface programming and CSS Styling.
                    </li>
                <li>In C, I learned not only the fundamental C concepts such as the pointer, heap, stack, queue, iterative and recursive sorting algorithms, data structures, and implementing abstract data types (ADT), but also very crucial abilities for any programming task in the future, such as efficiently using Git for collaboration and using debuggers like GDB for debugging. I also gained the ability to use Linux Shell commands and some precise experiences. To be more specific, when working on CS136 labs, the difficulty of debugging a huge program revealed the importance of separate compilation. It was so helpful especially when dealing with memory leaks in ADT implementation. Whenever I add a function, I compile it and tackle the error by drawing a memory diagram to give me a visual and following the computer logic with no assumption to the code. By practicing, I became more skillful and patient when debugging and wrote more efficient and readable code now.
                    </li>
                <li>Algorithms are very important in programming because it determines the efficiency of code operation. Learning and researching different algorithms is an interesting thing. There is an example which I studied many years ago to demonstrate <i>selection-sort</i> and <i>insertion-sort</i>. As a practice, I wrote the code in JavaScript and show by HTML/CSS. 
                    <a href="https://www.zhangxiaoda.club/sort_demo/index.html" target="_blank noopener"> (click here to try it<LinkIcon />)</a></li>
              </ul>
                
          </li>

              <li className="title" style={{marginTop: '1.2rem'}}>Web Design
                  <ul>
                    <li>I learned the necessary frontend technology (HTML, CSS and JaveScriptfor) for web design in high school.
                      </li>
                    <li>My first personal website was builded with Vue3 in 2023. I understood some concepts, such as single-page design, life cycle, declarative and component-based programming, TypeScript etc. 
                        </li>
                    <li>I also learnt React afterward and understood JSX.  As a result, I migrated my website from Vue.js to React.js.
                        </li>
                    </ul>

                    <p>While I learned React and re-designed my web with it, <em style={{fontWeight:"500"}}>I found Vue and React are the same fundamentals. The main difference is between Templates(Vue) and JSX(React). Vue's progressive building components are easy to learn for beginners, React is more like functional programming.</em> In my design, I used some skills to make up my web. 
                      For example, self-adaption by listening to window size to optimize the view on both large screen (desktop) and small screen(mobile), adding animation by programming for DOM, etc. </p>
                    <p style={{color:'#6699FF', fontWeight:600, fontStyle:'italic'}}>Now you are reading in the React version. Here is <a href="https://zhangxiaoda.club/vue" target="_blank noopener  noreferrer" >the Vue version<LinkIcon />.</a></p>
              </li>  
              <li className="title" style={{marginTop: "1.2rem"}}>Basic Skills or Knowledge
                    <p>VS Code, GIT, Microsoft Project, MySQL, Arduino, HTTP(GET/POST), TIMESTAMP, JSON, XML, Python, Node.js etc.</p>
              </li>
        </ul>
        <div>
          <p style={{textAlign:'center'}}><i>Programming code can be found in my   <a href="https://github.com/52hz-Daniel/myCode.git" target="_blank noopener" >GitHub</a></i></p>
        </div>
    </div>

    </>
    )
}
