import React from "react";
import { Link } from "react-router-dom";
import CloseBtn from "./close-button.tsx"

export default function Band(){
    return(
        <>
        <div className = "main1Field">
        <div className="subNav">
            <Link to="..">Activities and Abilities</Link> &gt;&gt; <span style={{textDecoration: 'underline'}}>Band Performance more detail</span>
        </div>
        
        <p style={{marginTop:'1.5rem'}}>&nbsp;&nbsp;&nbsp;&nbsp;
            In the first class of my high school elective band course, the jazz instructor asked for a drummer. We looked at each other and nobody dared to stand up, since none of us can play drums. I struggled with whether I should try it out. If I did not take a shot, I would most likely not find my place in the band. However, if I tried to be a drummer, my lack of experience would be exposed. I was afraid I couldn't meet the expectations, but I persuaded myself that it just might be the opportunity that led me on a new adventure. I stepped up, even though I had no idea how this crazy attempt would play out. It turns out that my decision was correct.
        </p>
        <p style={{}}>&nbsp;&nbsp;&nbsp;&nbsp;
        After becoming a drummer in our class, I started learning drums the same way I learned lighting. Music Box, a new band that was lacking a drummer, invited me to fill the role. It not only provided me with great potential opportunities but also a sense of acceptance. This feeling was the precious reward of my exploration. I can't stop thinking about the fact that, if did not pick up those drumsticks in class, I wouldn't be in a band today.
        </p>
        <div style={{display:'inline-flex', flexFlow:'row wrap', placeContent:'center', justifyContent:'space-around'}}>
        <img alt="beat_drum" className="photo" src="/assets_react/drum.jpg" width="400" height='400'/>
        <img alt="play_bass" className="photo" src="/assets_react/bass1.jpg" width="500" height="400"/>
        </div>
        
        
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        We developed ourselves in Music Box, becoming great friends, and playing over ten concerts at school. On the stage, I am so immersed in the music that I don't even see the reaction of the audience below. When high fives and applause come after the show, that is when I feel on top of the world. When teachers who are usually serious come up to me after shows and say, "Xiaoda, that was amazing! ", I know that what I am doing is worthwhile.
        </p>
        
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        These small attempts, such as playing drums and managing lighting, have become definitive moments in my life, enriching me as a person with multiple, unique sides. I am more willing to accept every opportunity that comes my way. No matter what the challenge is, I have developed myself into an explorer who is willing to embrace the future. I believe everything that comes into my life is a beautiful adventure. All I should do is follow my heart into the unknown and enjoy the applause that is sure to come.
        </p>
        
        
        </div>
        <CloseBtn path='..' />
        </>
    )
}