import React from 'react';
import NameCover from './nameCover.tsx'
import Welcome from './welcome.tsx'

export default function HomePage() {
  console.log("into Home Page")
    return(
      <>
      <main className="main2Field" >
        <div></div>
        <div>
          <NameCover />
        </div>
        <div style={{marginLeft: "3rem", minWidth: "20rem"}}>
          <Welcome />
        </div>
        <div></div>
      </main>
      </>
    );
}