import React from 'react';
import LinkIcon from "./linkicon.tsx";

export default function Welcome() {
    return(
        <>
            <div style={{padding:'1.5rem 1.6rem'}}>
                <h2 style={{
                    paddingBottom: '0.5rem', 
                    fontWeight:500,
                    }}>Welcome to my site</h2>
            
                <p>After I created <a href='https://zhangxiaoda.club/vue' target='_blank noopenner noreferrer'><i>my first website with Vue3<LinkIcon /></i></a> in 2023, I learned React and migrated it from <em>Vue</em> to <em>React</em>. The purpose of the website is to study front-end and back-end technology and practice coding, including client and server, MySQL connection, data communication and exchange, etc. Now it's a great chance to put my resume here &#128516;</p>
            </div>
        </>
    )
}