import React, {useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";

export default function Menu(){
    console.log("into Menu")
    const location=useLocation()
    const actStyle={color:'var(--vt-c-tag-a)'}
    const [ item, setItem]=useState(0)

    useEffect(()=>{ 
        if(location.pathname.toLowerCase().includes("/coopwork")) setItem(1)
        else if(location.pathname.toLowerCase().includes("/academic")) setItem(2)
        else if(location.pathname.toLowerCase().includes("/activity")) setItem(3)
        else if(location.pathname.toLowerCase().includes("/self-study")) setItem(4)
        else if(location.pathname.toLowerCase().includes("/interest")) setItem(5)
        else setItem(0);
    },[location.pathname]
    ) 
  return(
    <>
    
    <ul className='menu'>
        <li><Link to='.' style={item===0? (actStyle) : {} } onClick={()=>setItem(0) } ><span style={{paddingRight:'8rem'}}>Home</span></Link></li>
        <li><Link to="coopwork" onClick={()=>setItem(1)} style={item===1? (actStyle) : {} }>Co-op and Internship</Link></li>
        <li><Link to="academic" onClick={()=>setItem(2)} style={item===2? (actStyle) : {} }>Academic Competence</Link></li>
        <li><Link to="self-study" onClick={()=>setItem(4)} style={item===4? (actStyle) : {} }>Self-Study Achievement</Link></li>
        <li><Link to="activity" onClick={()=>setItem(3)} style={item===3? (actStyle) : {} }>Activities and Abilities</Link></li>
        <li><Link to="interest" onClick={()=>setItem(5)} style={item===5? (actStyle) : {} }>Miscellaneous Interests</Link></li>
    </ul>

    </>
  )
}