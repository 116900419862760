import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import HomePage from './pages/homePage.tsx'
import Coopwork from './pages/coopwork.tsx'
import Academic from './pages/academic.tsx'
import Activity from './pages/activity.tsx'
import Achievement from './pages/self_study.tsx'
import Interest from './pages/interest.tsx'
import ActivityRoot from './pages/activity-root.tsx'
import Immc from './pages/act_immc.tsx'
import Igem from './pages/act_igem.tsx'
import Band from './pages/act_band.tsx'
import Lighting from './pages/act_lighting.tsx'


//import reportWebVitals from './reportWebVitals';

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./error-page";
console.log("into index.js")

function updateSize () { 
  const hd=document.getElementById("hd").offsetHeight
  const ft=document.getElementById("ft").offsetHeight
  console.log("Head/Foot Height ="+hd+"/"+ft)
  console.log("window width="+window.innerHeight)

let bodyHeight=window.innerHeight-hd-ft
let str="min-height:"+bodyHeight+"px";
let setH=document.getElementById('root')
if(setH==null) { console.log("Effect: can not get by ID!")}
else { setH.setAttribute('style',str);  console.log(str) }
}

updateSize()
window.addEventListener('resize', updateSize)
window.onbeforeunload=()=>{ window.removeEventListener('resize',updateSize) }
document.addEventListener("unLoad", bye)

function bye(){
  alert("close now")
}

const router=createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path:"/",
        element:<HomePage />
      },
      {
        path:"coopwork",
        state: 999,
        element: <Coopwork />,
      },
      {
        path:"academic",
        element: <Academic />,
      },
      {
        path:"activity",
        element: <ActivityRoot />,
        children:[
          {
            path:"/activity",
            element:<Activity />,
          },
          {
            path:"immc",
            element:<Immc />,
          },
          {
            path:"igem",
            element:<Igem />,
          },
          {
            path:"band",
            element:<Band />,
          },
          {
            path:"lighting",
            element:<Lighting />,
          },
        ]
      },
      {
        path:"self-study",
        element: <Achievement />
      },
      {
        path:"interest",
        element: <Interest />
      }
    ]
  },
 
]);
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
