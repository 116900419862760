import React from "react";
import { useState } from "react";
import '../styles/activity.css'
import { Link } from "react-router-dom";
import CloseBtn from "./close-button.tsx"

const std_photoW=400  
const std_photoH=250  
const std_photoAmount=3
const std_step=1   //move speed
const std_freq=20  //move speed

export default function Igem(){
    let photoWidth=std_photoW
    let photoHeight=std_photoH
    let photoAmount=std_photoAmount
     
    const [moveLeft, setMoveLeft]=useState(0)  
    let move=moveLeft
    const [saveNumber, setSaveNumber]=useState(0)
    let curNumber=saveNumber

    let dir=0
    let timer: any
    let step=std_step  //move step (speed)
    let freq = std_freq //move speed
    let count=0

        function moveSlides(){
            curNumber=curNumber+dir
            console.log("into moveSlides()***")
            action()
    
            function action(){
                step=count*count
                count=count+1
                move=move-step*dir
                
                if(dir>0){
                    if(move>-photoWidth*curNumber){ 
                        timer=setTimeout(action,freq)
                        setMoveLeft(move)
                        }  
                    else{
                        move=(-(photoWidth)*curNumber)
                        setMoveLeft(move)
                        setSaveNumber(curNumber)
                        count=0
                        step=std_step
                        clearTimeout(timer)
                    }  
                }
                if(dir<0){
                    if(move<-photoWidth*curNumber ){ 
                        timer=setTimeout(action,freq)
                        setMoveLeft(move)
                    }
                    else{
                        move=(-(photoWidth)*curNumber)
                        setMoveLeft(move)
                        setSaveNumber(curNumber)
                        count=0
                        step=std_step
                        clearTimeout(timer)
                    }
                }
            }
            
        }

  return(
    <>
 
    <div className="main1Field">
        <div className="subNav">
            <Link to="..">Activities and Abilities</Link> &gt;&gt; <span style={{textDecoration: 'underline'}}>iGEM more detail</span>
        </div>
        <div className="igem-web">
            <a href="https://2020.igem.org/Team:BNDS_China" target="_blank noopenner noreferrer"><p><span style={{fontWeight: 600}}>click here to visit our iGEM website</span></p></a>
        </div>
    </div>

    <div className="container">      
            <div id="list" className="photoList" style={{width:photoWidth+'px', height:photoHeight+'px'}}> 
              <img alt="igem_photo" src="/assets_react/igem_1.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
              <img alt="igem_photo" src="/assets_react/igem_2.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}}/>
              <img alt="igem_photo" src="/assets_react/igem_3.jpg"  style={{width:photoWidth+'px', height:photoHeight+'px', left:moveLeft+'px'}} />
              
    
                <div className="rtIcon" style={{display: curNumber===photoAmount-1? 'none' : 'block'}} ><img alt="right" onClick={()=>{ dir=1; moveSlides() } } src="/assets_react/rt_icon.png" /></div>
                <div className="lfIcon" style={{display: curNumber===0? 'none' : 'block'}}><img alt="left" onClick={()=>{dir=-1; moveSlides()}}  src="/assets_react/lf_icon.png" /></div>
            </div>
            <div className="boxList">
            <div className="sbox" style={{ backgroundColor: saveNumber===0? '#ccc' : ''}} onClick={()=>{curNumber=(0); setSaveNumber(0); setMoveLeft(-photoWidth*curNumber)}}></div>
            <div className="sbox" style={{ backgroundColor: saveNumber===1? '#ccc' : ''}} onClick={()=>{curNumber=(1); setSaveNumber(1); setMoveLeft(-photoWidth*curNumber)}}></div>
            <div className="sbox" style={{ backgroundColor: saveNumber===2? '#ccc' : ''}} onClick={()=>{curNumber=(2); setSaveNumber(2); setMoveLeft(-photoWidth*curNumber)}}></div>
            
        </div>
  
    </div>
    <CloseBtn path='..' />
    </>
  )
}