

import React from 'react'
import '../styles/nameCover.css'
import  AnimateText  from './animateText.tsx'

export default function NameCover() {

    return(
    <div>
    <img alt="Dada" className="logo" src="/assets_react/photo.jpg" width={130} height='auto'  />
    <div className="name">Xiaoda Zhang</div>   
    <p id="iam" className="text">I&#39;m a</p>
    <div className="char">
        <AnimateText />  
    </div>
    <p id="uwater" className="text">in University of Waterloo</p>
    <div className="mailto">
    <img alt="mailto" className="mIcon" src="/assets_react/mailto.svg" width="30" height="20" />
        <a className="mAdd" href="mailto:d42zhang@uwaterloo.ca"> contact me: d42zhang@uwaterloo.ca</a>
    </div>  
    <div className="icon_container">
      <div className="linkedin_link">
        <img alt="LinkedIn" className="icon_linkin" src="/assets_react/icons8-linkedin.svg" width="30" height="30" />
        <a href="https://www.linkedin.com/in/xiaoda-zhang/" target="_blank"  rel="noopener noreferrer">&nbsp;LinkedIn</a>
      </div>
      <div className="github_link">
        <img alt="GitHub" className="icon_github" src="/assets_react/icon-github.png"   width="28" height="28" />
        <a href="https://github.com/52hz-Daniel/" target="_blank" rel="noopener noreferrer"> &nbsp;GitHub</a>
      </div>
    </div>
        </div>
    )
}