import React from "react";
import { Link } from "react-router-dom";
import CloseBtn from "./close-button.tsx"

export default function Lighting(){
    return(
        <>
        <div className = "main1Field">
        <div className="subNav">
            <Link to="..">Activities and Abilities</Link> &gt;&gt; <span style={{textDecoration: 'underline'}}>Stage Lighting more detail</span>
        </div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
            As the audience applauded, I knew my performance in the singing competition was beyond imagination. Nevertheless, they had not seen my face, since I was in the dark, backstage, all along. 
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        I am not a singer, but a stage lighting manager. I had no idea what challenges I would face when I came across stage lighting in 8th grade. The English Drama Night was held every year for each class to perform its self-directed drama show. Everyone was expected to play a role in this show, although not everyone would be on stage. Those who didn't have roles on stage, including me, were expected to be in charge of the backstage. I tried the role of lighting crew leader, starting by asking the simplest questions about how to use the control panel.
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        The task was challenging, but it became the reason why I participated in the Student Union at my high school. Stage lighting never had a place in any school event, or rather, no one could operate the stage light, yet there are so many activities that require lights. I realized this situation, and I tried to make a difference through my dedication and experience in lighting. None of these opportunities would have been possible if I had not seized the opportunity during English Drama Night in 8th grade. I realized how an unintentional skill became my strength.
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        Members of the Student Union all took on different responsibilities. While I was editing promos and planning proposals for various events, I took the initiative to tackle all lighting tasks for various school performances based on my working experience. My attempts have added a great deal of color to the performances and have gained the love and appreciation of the audience. The reputation I built led to more clubs inviting me to control the lighting for their shows.
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        As more and more events required my help, I gradually became a little overwhelmed. I realized that my ability alone was not enough to support the entire school, and that if I could pass on my skills to others, we might be able to manage the lighting for different events together. I imparted my experience in detail to a student whose later lighting performance was applauded by many audiences. These experiences prompted me to go further. Now under my leadership, a small community of lighting technicians was formed, serving almost every event in the large school community.
        </p>
        <div className ="title" style={{textAlign: 'center'}}>What is "Volunteerism" in my opinion?</div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        I have volunteered for several groups in society and at school, but I think my efforts in lighting have been the most rewarding. I have watched an unimpressive role become recognized through my promotion, building a small community that welcomes students to devote in and volunteer backstage. This is what I understand volunteerism to be at its best. Contributing to my community doesn't have to happen in a faraway place, nor does it have to be a majestic feat that changes the world. Creating value for the positive development of the community and bringing a group together going further with my ability is what I try to do all the time.
        </p>
        </div>
        <CloseBtn path=".." />
        </>
    )
}