import React from "react";
import Tableau from "./tableau.jsx";
import "../styles/self-study.css"
export default function Achievement(){
  return(
    <>
      <div className="main1Field">
        <ul>
        <li className="title">Certificates
        <div className="space">
            <p>
            I pursued courses and career paths on LinkedIn and earned certificates from Microsoft, Atlassian, and LinkedIn in Generative AI, Data Analysis, Agile Project Management, and Business Analysis. 
            </p>
        </div>
        <div className="certi_box">
           <a style={{ }} href = "https://www.linkedin.com/learning/certificates/69bb9273d6140c11be84916d617484a1393e05c7faa0e0fd53fd95db2d1dcbaa" target="_blank"  rel="noreferrer noopener"><img alt="certification-1" className="certification" src="/assets_react/certification-1.jpg" width={350} height='auto'  /></a>
           <a href = "https://www.linkedin.com/learning/certificates/6a97cd9d42d34dd42ca1a0c2299f813fc522f4dd2f35ec9daef32400473bd48c" target="_blank"  rel="noreferrer noopener"><img alt="certification-2" className="certification" src="/assets_react/certification-2.jpg" width={350} height='auto'  /></a>
           <a href = "https://www.linkedin.com/learning/certificates/c870eab0164181fcb10aa926b0126dddeffd1f2c36263be36ca8980259e62d09" target="_blank"  rel="noreferrer noopener"><img alt="certification-3" className="certification" src="/assets_react/certification-3.jpg" width={350} height='auto'  /></a>
           <a href = "https://www.linkedin.com/learning/certificates/fbcfa9a3ec1e5a2b6cfd4bc90688e2547abe1ddd55cdee669d2890514615630d" target="_blank"  rel="noreferrer noopener"><img alt="certification-4" className="certification" src="/assets_react/certification-4.jpg" width={350} height='auto'  /></a>


        </div>     
      
        </li>

        <li className="title space">Data Analysis Practice with Power BI and Tableau
            <p className="space">
            I developed a solid understanding of fundamental data analysis concepts and gained hands-on experience using tools like Power BI and Tableau. I successfully built end-to-end workflows, which allowed me to present sample data (website browsing statistics) using Tableau.
            </p>
            <div>
            <Tableau /> 
            </div>
        </li>
    </ul>
    </div>
    </>
  )
}