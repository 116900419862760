import React from "react";
import '../styles/activity.css'
import { Link } from "react-router-dom";
export default function Activity(){
  return(
    <>
    <div className="main1Field">
        <p>By participating in the following activities, I have improved in many aspects, such as time management, teamwork, interpretation, leadership, self-teaching, fast learning, planning and organizing, self-motivated and initiative-driven attitude, interpersonal skills, responsibilities, being more patient, etc. I also gained skills through practice, such as HTML, CSS, JavaScript, Microsoft Project, Python, Excel, etc.</p>
    <ul>
    <li className="title">
        International Mathematical Modeling Challenge (IMMC), Finalist for International Contest
        <p className = "job_role">Team Leader</p>
        <p>
            With the knowledge I learned from the Mathematical Modeling course and the experience I gained from participating in the IMMC competition, I believe that mathematical modelling is the method of solving real-life issues in a mathematical way, and the process of distilling the underlying logic from the complex contradictions in nature. I was inspired by the tight connection of math with computer science and society, hoping to apply computers to host mathematical algorithms that could solve practical issues and eliminate as many of the contradictions in the world as possible.            </p>
        <p className="tags"><i>
            #Leadership #Analytical thinking #Managing multiple priorities #Microsoft Project #Excel #Python
        </i>  
        </p>
        <div className="details">
            <Link to="immc">more details =&gt;</Link>
        </div>
    </li>
    <li className="title">
        International Genetically Engineered Machine Competition (iGEM), Gold Medal
        <p className = "job_role">Team Web Developer</p>
        <div className="space">
        <p>I joined the iGEM (International Genetically Engineered Machine competition) team in my high school BNDS (Beijing National Day School). 
        I was responsible for building and updating the competition website for the team. Although the time was tight and the task was heavy during 
        the preparation and competition, each member cooperated with each other very well.  Finally, our team won the gold medal. Building the website 
        and participating in the competition left me with good memories and rich practical experience.</p>
        <p className="tags"><i>
            #Time management #Teamwork #Results-oriented #HTML #CSS #JavaScript
        </i>  
        </p>
        </div>
        <div className="details">
            <Link to="igem">more details =&gt;</Link>
        </div>
    </li>
    <li className="title">
        Band Performance
        <p className = "job_role">Organizer, Drum, and Bass</p>
        <p>
            Driven by my passion for music, I learned to play the drums and bass by self-teaching in my spare time during high school and joined a student band named Music Box. We rehearsed together and performed on stage frequently over two years, presenting more than 10 concerts in school. As one of the core members, I coordinate each member's availability to create a reasonable rehearsal schedule to ensure that enough songs are completed and performed each semester. I believe everything that comes into my life is a beautiful adventure. All I should do is follow my heart into the unknown and enjoy the applause that is sure to come.
        </p>
        <p className="tags"><i>
            #Self-learning #Collaboration #Initiative driven #Growth mindset  #Public performance
        </i>  
        </p>
        <div className="details">
            <Link to="band">more details =&gt;</Link>
        </div>
    </li>
    <li className="title">
        Stage Lighting
        <p className = "job_role">Volunteer and Initiator</p>
        <p>
            I learned stage lighting control by myself and gradually became a facility expert. Then I taught other students to master this skill and formed a stage lighting community to share experiences. I am more willing to accept every opportunity that comes my way. No matter what the challenge is, I have developed myself into an explorer who is willing to embrace the future.            </p>
        <p className="tags"><i>
            #Can-do attitude #Volunteerism #Fast learner #Communication #Patience
        </i>  
        </p>
        <div className="details">
            <Link to="lighting">more details =&gt;</Link>
        </div>
    </li>
</ul>
</div>
</>
  )
}