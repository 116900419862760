import React from "react"
import { useState, useEffect } from "react" 

const textList=["Self-motivated Learner", "Programming Enthusiast", "Music Lover","Team Player"]  
//let color='text-green-500'
let i=0  //当前打印的字符位置
let l=0  //字符串数组元素
let step=1  //步长（正为向右，负为向左）
const showTime=10  //字符打印完停留时长
const colorGroup=['#6699FF','#CC9900','#CC00FF','#006666']
let setColor='#6699FF'

export default function AnimateText() {  

    const [text, setText]=useState("");
    useEffect(()=>{
 
          //打字和删除动画
      const timer=setInterval(()=>{
        if(i<=textList[l].length && i>=0) {  //i为当前打印字符的位置
          setText( textList[l].slice(0, i) + '|' )  //截取0到i字符
          i+=step;  //下一个字符
        }
        else if(i<textList[l].length+showTime && i>textList[l].length){  //增加停留时间
          setText( textList[l] )
          i+=step; 
        }
        else{  
          step=-2
          if(i<0 ) {  //光标向左移动已到达原点左侧
            if( l<textList.length-1) l++  //打下一个字符串（如有）
            else l=0  //否则打第一个字符串
            step=1   //方向向右步长为1
            i=-1
          } 
          i+=step  //位置增加或减小步长
        }
      }, 100)
      

      return ()=> {  
        setColor=colorGroup[l]
        clearInterval(timer)
      }
    }, [text])

  return (
      <div style={{fontWeight:800, color: setColor}}>{text}</div>
  );
}

//export default AnimateTyping;

     /* 用timeout实现一次打字动画
      const timeout = setTimeout(()=>{
        let text_str = textList[0].slice(0, text1.length+1)
        text_str= text1.length<textList[0].length ? text_str+'|' : text_str
        setText1(text_str)
      },150)
      */
     //clearTimeout(timeout)
     /* 用react-typewriter-hook 实现的打字效果，一次
     npm install react-typewriter-hook 安装打字动画包实现typing效果
     import useTypewriter from "react-typewriter-hook"
     const [nloop, setnLoop] = useState(0)
     const talk = useTypewriter(textList[nloop]);
     */