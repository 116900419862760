import React, { useState, useEffect } from "react";

export default function Tableau(){
 
    useEffect(()=>{
        var divElement = document.getElementById('viz1712562144372');
        var vizElement = divElement.getElementsByTagName('object')[0];
        vizElement.style.width='100%';
        vizElement.style.height=(divElement.offsetWidth*0.75)+'px';   
        var scriptElement = document.createElement('script');  
        scriptElement.src = 'https://public.tableau.com/javascripts/api/viz_v1.js';
        vizElement.parentNode.insertBefore(scriptElement, vizElement);             
    },[])
 
    return(
        <>
        <div className='tableauPlaceholder' id='viz1712562144372' style={{position: 'relative'}}>
        <noscript>
          <a href='#'><img alt='Visit Amount BY Day ' src='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;da&#47;dailyTest&#47;Sheet1&#47;1_rss.png' style={{border: 'none'}} /></a>
        </noscript>
        <object className='tableauViz'  style={{display:'none'}}>
          <param name='host_url' value='https%3A%2F%2Fpublic.tableau.com%2F' /> 
          <param name='embed_code_version' value='3' /> 
          <param name='site_root' value='' />
          <param name='name' value='dailyTest&#47;Sheet1' />
          <param name='tabs' value='no' />
          <param name='toolbar' value='yes' />
          <param name='static_image' value='https:&#47;&#47;public.tableau.com&#47;static&#47;images&#47;da&#47;dailyTest&#47;Sheet1&#47;1.png' /> 
          <param name='animate_transition' value='yes' />
          <param name='display_static_image' value='yes' />
          <param name='display_spinner' value='yes' />
          <param name='display_overlay' value='yes' />
          <param name='display_count' value='yes' />
          <param name='language' value='en-US' />
        </object>
      </div>   
     </>
    )
}