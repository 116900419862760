import React from "react";
import { Link } from "react-router-dom";

export default function CloseBtn(props: any){
    const styles=
    {      
        padding: '3px 30px',
        margin: '1rem auto',
        cursor: 'pointer',
        color: 'var(--vt-c-btn-font)',
        backgroundColor: 'var(--vt-c-btn-bg)',
        borderColor: 'var(--vt-c-green-bg)',
        borderRadius: '6px',
    }

    console.log(props)
    const toPath=props.path
    console.log("toPath="+toPath)
    //暂没用props传来的参数，直接给to赋值了
    return(
        <>
        <div style={{textAlign:'center', marginTop:'2rem', marginBottom:'2rem'}}>
            <Link to={'..'}  style={styles}><span>Close</span></Link>
        </div>
        </>
    )
}