import React from "react";
import { Link } from "react-router-dom";
import CloseBtn from "./close-button.tsx"

export default function Immc(){
  return(
    <>
   
    <div className="main1Field" >
        <div className="subNav">
            <Link to="..">Activities and Abilities</Link> &gt;&gt; <span style={{textDecoration: 'underline'}}>IMMC more detail</span>
        </div>
         <p>
            &nbsp;&nbsp;&nbsp;&nbsp;The first time I applied mathematics in a real-world situation to solve a complex problem was in the International Mathematical Modeling Challenge (IMMC) competition, where my team developed and validated an assessment model to measure a country's or region's readiness to respond to biosecurity threats, such as the Covid-19 pandemic. The model revealed deficiencies in healthcare systems and public policies to prevent more potential outbreaks and give advance notice to governments. While the two current worldwide assessment systems, Joint External Evaluation (JEE) developed by World Health Organization and Global Health Security Index (GHSI) developed by the Center for Health Security, Johns Hopkins Bloomberg School of Public Health, were not effective in responding to the outbreak, the assessment of my new model was more precise, reflecting on the actual pandemic situation. The model was based on two methods, Entropy Weight Method (making a strategy based on value dispersion and information density) and the TOPSIS method (a multi‑criteria decision analysis method).
            </p>
            <div style={{display:'inline-flex', flexFlow:'row wrap', placeContent:'center', justifyContent:'space-around'}}>
            <img alt="IMMC_team" className="photo" src="/assets_react/immc_team.jpg" width="400" height="300"/>
            <img alt="IMMC_final_award" className="photo" src="/assets_react/immc_final_award.jpg" width="400" height="300" />
            </div>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;
            In another paper, I built an evaluation model that analyzes who is the greatest athlete of all time in a specific sport. The model is based on Analytic Hierarchy Process (AHP), which is a decision-making method that compares multiple alternatives, each with several criteria to help select the best option.
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        The IMMC competition was requiring participants to construct a mathematical model solving real-life issues and write a 20 pages paper about the model in a 5 days period. Therefore, it is very crucial for me to collaborate with the other 3 teammates efficiently. With my experience in project management, I suggested a valid plan that helped us to complete the paper 5 hours before the deadline using Microsoft Project. My capabilities to handle multiple events simultaneously and organize coworkers to accomplish the same goal were strengthened in the process. 
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
            After visiting an exhibition about the "Seven Bridges of Königsberg", I applied its underlying principle to real-life issues and published the essay “From the Euler Graph to the Beijing Subway System” in a school journal. 
        </p>
    

        <div style={{textAlign:'center', marginTop:"1.2rem", fontWeight:800}}>What is "Leadership" in my opinion?</div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        In my point of view, a leader is not necessarily a speaker but a listener. I understand the importance of listening to others’ ideas and concerns in a team. Therefore, instead of instructing teammates to follow my perspective, I always listen to others' points of view and resolve differences of opinion in discussions. By being a mediator in a project, I can boost the efficiency of teamwork and ensure the team achieves the goal before the agreed deadline.
        </p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;
        Mathematics, as a language of reason, provides me with the most reliable perspective to view the ongoing issues in the world, such as cyber violence, wars, and conflicts between political parties and races. Computers enable the implementation and verification of mathematical models, while mathematics lays the theoretical foundation for computer science.
        </p>

        <p className="subtitle" style={{marginTop:"1.2rem", fontWeight:600}}>Reference Documents (pdf):  </p>
        <ul>
        <li><a href="/assets_react/essay_IMMC21892281.pdf" target="_blank noopenner noreferrer">Essay of IMMC (No.IMMC21892281)</a></li>
        <li><a href="/assets_react/essay_IMMC21484316.pdf"  target="_blank noopenner noreferrer">Essay of IMMC (No.IMMC21484316)</a></li>
        <li><a href="https://mp.weixin.qq.com/s/eDhywWO6k5pxDfEJFxJlJQ"  target="_blank noopenner noreferrer">Homework of mathmatic modeling</a></li>
        </ul>
       
    </div>
    <CloseBtn path='..' />

    </>
  )
}